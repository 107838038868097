export const INITIAL_BALANCE = 250_000
export const CURRENT_YEAR = 2023
export const PERIOD_DURATION = 60 * 4

const periods2021 = [
    "3Q 2021",
    "4Q 2021",

    "1Q 2022",
    "2Q 2022",
    "3Q 2022",
    "4Q 2022",

    "1Q 2023",
    "2Q 2023",
    "3Q 2023",
    "4Q 2023",

    "1Q 2024",
    "2Q 2024",
    "3Q 2024",
    "4Q 2024",

    "1Q 2025",
    "2Q 2025",
    "3Q 2025",
    "4Q 2025",

    "1Q 2026",
    "2Q 2026",
]

const periods2022 = [
    "3Q 2022",
    "4Q 2022",

    "1Q 2023",
    "2Q 2023",
    "3Q 2023",
    "4Q 2023",

    "1Q 2024",
    "2Q 2024",
    "3Q 2024",
    "4Q 2024",

    "1Q 2025",
    "2Q 2025",
    "3Q 2025",
    "4Q 2025",

    "1Q 2026",
    "2Q 2026",
    "3Q 2026",
    "4Q 2026",

    "1Q 2027",
    "2Q 2027",
]

const periods2023 = [
    "3Q 2023",
    "4Q 2023",

    "1Q 2024",
    "2Q 2024",
    "3Q 2024",
    "4Q 2024",

    "1Q 2025",
    "2Q 2025",
    "3Q 2025",
    "4Q 2025",

    "1Q 2026",
    "2Q 2026",
    "3Q 2026",
    "4Q 2026",

    "1Q 2027",
    "2Q 2027",
    "3Q 2027",
    "4Q 2027",

    "1Q 2028",
    "2Q 2028",
]

export const getPeriods = (offline, year) => offline && year === "2021" ? periods2021 : offline && year === "2022" ? periods2022 : periods2023 