import GoogleMapReact from 'google-map-react';
import "./styles.scss"

const Home = () => {
    return <div className="screen-home-container col">
        <section className="main">
            <div className="image" />
            <h1>{`Събитието, целящо да повиши\nфинансовата грамотност на младите`}</h1>
        </section>
        <section className="description col">
            <div className="icon icon-levcheto" />
            <h1>Какво е Левчето?</h1>
            <p>Левчето е еднодневен финансов форум, който цели да повиши финансовата грамотност на младите хора в България. На събитието ежегодно се събират професионалисти от финансовата сфера, които изнасят кратки лекции по теми като икономика и лични финанси. След това се сформира панелна дискусия, на която аудиторията може да задава конкретни въпроси към лекторите.</p>
            <p>Кулминацията на събитието е интерактивна игра, която представлява симулация на стокова борса. Всеки участник има шанса да търгува и инвестира с виртуални пари в период от 1 час и 20 минути, като всеки победител печели парична награда.</p>
            <p>Събитието събира младежи с интерес към инвестициите и финансите и им дава възможността да обсъдят актуални теми, да се запознаят с опитни хора от сферата и да обменят идеи.</p>
        </section>
        <section className="sponsors">
            <h2>Домакин на събитието</h2>
            <div className="row">
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/FinanceAcademy.png')})` }} alt='sponsor-1' />
            </div>
            <h2>Генерални спонсори</h2>
            <div className="row">
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/FrontPeak.png')})` }} alt='sponsor-2' />
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/LuteCredit.webp')})` }} alt='sponsor-3' />
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/Partners.png')})` }} alt='sponsor-4' />
            </div>
            <h2>Стратегически спонсори</h2>
            <div className="row">
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/RedBull.jpg')})` }} alt='sponsor-5' />
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/Sofoods.jpg')})` }} alt='sponsor-6' />
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/DolcheGusto.png')})` }} alt='sponsor-7' />
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/StudyHub.png')})` }} alt='sponsor-8' />
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/Native.png')})` }} alt='sponsor-9' />
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/NYF.png')})` }} alt='sponsor-10' />
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/Trotoara.png')})` }} alt='sponsor-11' />
            </div>
            <h2>Медийни партньори</h2>
            <div className="row">
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/EconomyBG.png')})` }} alt='sponsor-12' />
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/RadioFocus.png')})` }} alt='sponsor-13' />
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/BNR.png')})` }} alt='sponsor-14' />
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/Economicbg.png')})` }} alt='sponsor-15' />
            </div>
        </section>
        <section className="row timeline">
            <div className="col">
                <p>2012</p>
                <div className="dot"><div className="line" /></div>
                <p>Първо издание в Софийски Университет</p>
            </div>

            <div className="col">
                <p>2015</p>
                <div className="dot"><div className="line" /></div>
                <p>Първата онлайн симулация</p>
            </div>

            <div className="col">
                <p>2020</p>
                <div className="dot"><div className="line" /></div>
                <p>Рекорден брой посетители</p>
            </div>

            <div className="col">
                <p>2022</p>
                <div className="dot"><div className="line" /></div>
                <p>Юбилейно издание</p>
            </div>
            <div className="col">
                <p>2023</p>
                <div className="dot"><div className="line" /></div>
                <p>11-то издание</p>
            </div>
        </section>
        <section className="footer">
            <div className="place-date">
                <h2>Място: <span>SoftUni, бул, Александър Малинов 78</span></h2>
                <h2>Дата и Час: <span>14 октомври 2023, 10:00 часа</span></h2>

                <div style={{ height: 400, width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                        defaultCenter={{ lat: 42.6361125, lng: 23.3697881 }}
                        defaultZoom={18}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => {
                            new maps.Marker({
                                position: { lat: 42.6361125, lng: 23.3697881 },
                                map: map,
                                draggable: false,
                            });
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/AIESEC.png')})` }} alt='AIESEC' />
                <div className="row row-social">
                    <a href="https://www.facebook.com/aiesec.bg" target="_blank" rel="noreferrer"><div className="icon icon-facebook" /></a>
                    <a href="https://instagram.com/aiesecbulgaria?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer"><div className="icon icon-instagram" /></a>
                    <a href="https://www.linkedin.com/company/aiesecbulgaria/" target="_blank" rel="noreferrer"><div className="icon icon-linkedin" /></a>
                </div>
            </div>
        </section>
    </div>
}

export default Home