import { Shared } from "components"
import { CURRENT_YEAR, getPeriods, INITIAL_BALANCE, PERIOD_DURATION } from "config/constants"
import { database } from "config/firebase"
import { child, get, ref, update } from "firebase/database"
import { omit, partition, range, sortBy } from "lodash"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { numberWithCommas, positionChange } from "utilities/helpers"
import "./styles.scss"

const Admin = () => {
    const navigate = useNavigate()
    const periods = useMemo(() => getPeriods(false), [])
    const { uid } = useSelector(({ general }) => general?.user) || {}
    const [users, setUsers] = useState([])
    const [periodLength, setPeriodLength] = useState(PERIOD_DURATION)

    const handleStart = () => range(0, 21).forEach((p, i) => setTimeout(() => {
        update(ref(database), { [`/currentPeriod`]: Number(p) })
        update(ref(database), { [`/timestamp`]: new Date() })
    }, Number(periodLength) * 1000 * i))

    const handleReset = () => update(ref(database), { [`/currentPeriod`]: Number(-1) })

    const handleStats = () => get(child(ref(database), `/`)).then((snapshot) => {
        const { [CURRENT_YEAR]: { prices }, currentPeriod, ...rest } = snapshot.val()
        setUsers(Object.entries(
            omit(rest, ['2021', '2022', '2023', 'currentPeriod', 'timestamp'])).map(([uid, { email, displayName, positions }]) => {
                const [opened, closed] = partition(positions, ({ profit }) => [null, undefined].includes(profit))
                const totalChange = opened.reduce((acc, position) => acc + Number(positionChange({ position, prices, currentPeriod, periods })), 0)
                const balance = INITIAL_BALANCE + totalChange + closed.reduce((acc, { profit }) => acc + Number(profit), 0)
                return { uid, email, displayName, balance }
            })
        )
    })


    if (!['QXYh6fWXSFZaI9Z6u9qQcnBkCLs2', "S7DqyXR1YsShcAg2VgZEbkcc4Cf2"].includes(uid)) {
        return <h2
            className="screen-admin-no-permissions"
            onClick={() => navigate('/admin')}
        >
            No Permissions
        </h2>
    }

    return <div className="screen-admin-container col">
        <div className="row row-buttons">
            <input placeholder="Количество" value={periodLength || ''}
                onChange={({ target: { value } }) => {
                    if (!/^\d+$/.test(value) && value !== '') return
                    setPeriodLength(value)
                }}
            />
            <Shared.Button text="Старт" onClick={handleStart} />
            <Shared.Button text="Ресет" onClick={handleReset} />
            <Shared.Button text="Статистика" onClick={handleStats} />
        </div>
        <div className="users-container">
            {sortBy(users, 'balance')?.reverse()?.map(({ uid, email, displayName, balance }) => (
                <div key={email} className="row row-user">
                    {uid} - {email} - {displayName} - ${numberWithCommas(balance)}
                </div>
            ))}
        </div>
    </div>
}

export default Admin