import { setModal } from "actions"
import { Shared } from "components"
import { INITIAL_BALANCE } from "config/constants"
import { isEmpty, partition } from "lodash"
import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2"
import { positionChange, numberWithCommas } from "utilities/helpers"
import "./styles.scss"

const Portfolio = ({ balance, margin, profit, handleSell, positions, prices, currentPeriod, hasFinished, periods }) => {
    const dispatch = useDispatch()
    const { uid } = useSelector(({ general }) => general?.user) || {}
    const [opened, closed] = useMemo(() => partition(positions, ({ profit }) => [null, undefined].includes(profit)), [positions])

    const totalChange = useMemo(() => opened.reduce((acc, position) => acc + Number(positionChange({ position, prices, currentPeriod, periods })), 0), [currentPeriod, periods, opened, prices])
    const total = useMemo(() => INITIAL_BALANCE + totalChange + closed.reduce((acc, { profit }) => acc + Number(profit), 0), [closed, totalChange])

    return <div className="game-portfolio-container col">
        <div className="row row-header">
            <h2>Портфолио</h2>
            <p className={`change ${totalChange > 0 ? 'positive' : totalChange < 0 ? 'negative' : 'zero'}`} >${numberWithCommas(totalChange)}</p>
        </div>
        <div className="game-portfolio-header">
            <div className="row">
                <p className="total">Обща стойност: </p>
                <span className="bold total">${(numberWithCommas(total))}</span>
            </div>
            <div className="row">
                <p>Пари: </p>
                <span>${numberWithCommas(balance)}</span>
            </div>
            <div className="row">
                <p>Използван марджин: </p>
                <span>${numberWithCommas(margin)}</span>
            </div>
            <div className="row">
                <p>П/З по позициите: </p>
                <span>${numberWithCommas(profit)}</span>
            </div>
        </div>
        <div className="game-portfolio-sell-all">
            <div className="row row-title">
                <p>Позиции</p>
                {!hasFinished && !isEmpty(opened) && <Shared.Button
                    text="Затвори всички"
                    onClick={() => Swal.fire({
                        title: 'Сигурни ли сте?',
                        text: "Всички отворени позиции ще бъдат ликвидирани.",
                        showCancelButton: true,
                        cancelButtonText: "Откажи",
                        cancelButtonColor: "#fa4460",
                        confirmButtonText: "Продължи",
                        confirmButtonColor: "#00ce14",
                        reverseButtons: true
                    }).then(({ isConfirmed }) => {
                        if (isConfirmed) {
                            opened.forEach((position) => {
                                const change = Number(positionChange({ position, prices, currentPeriod, periods }))
                                handleSell({ position, change })
                            })
                            Swal.fire({
                                title: `Успешна ликвидация на всички позиции`,
                                text: "Вашата поръчка е изпълнена",
                                icon: "success",
                                confirmButtonColor: "#0099ff",
                                confirmButtonText: "Страхотно!"
                            })
                        }
                    })}
                    background="#0099ff"
                />}
            </div>
            {!hasFinished && <span>Кликнете позиция, за да я затворите</span>}
        </div>
        <div className="game-portfolio-content">
            {isEmpty(positions)
                ? <p className="no-positions">Нямате отворени позиции</p>
                : opened?.map((position) => {
                    const { type, name, price, quantity, lot, _id } = position
                    const change = Number(positionChange({ position, prices, currentPeriod, periods }))
                    return (
                        <div
                            key={_id}
                            className="row row-position"
                            onClick={() => !hasFinished && dispatch(setModal({
                                isOpen: true, type: "SellPosition", props: {
                                    uid,
                                    position,
                                    change,
                                    value: ((Number(price) * Number(quantity) * Number(lot)) + change),
                                    newPrice: (Number(prices?.[periods[currentPeriod]]?.[name]?.price) * Number(lot)) - (Number(price) * Number(lot)),
                                    handleSell
                                }
                            }))}>
                            <div className="col">
                                <p>{name} - {type === 'long' ? "Дълга" : "Къса"}</p>
                                <p>${price}</p>
                            </div>
                            <div className="col col-value">
                                <p>${numberWithCommas((price * quantity * lot) + change)}</p>
                                <p className={`change ${change > 0 ? 'positive' : change < 0 ? 'negative' : 'zero'}`} >${numberWithCommas(change)}</p>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    </div>
}

export default Portfolio