import { setModal, setNextPeriod } from "actions"
import { Shared } from "components"
import { getPeriods } from "config/constants"
import { auth, database } from "config/firebase"
import { signOut } from "firebase/auth"
import { onValue, ref } from "firebase/database"
import { useCountdown, useQuery } from "hooks"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Popup from "reactjs-popup"
import Swal from "sweetalert2"
import "./styles.scss"

const CountdownTimer = ({ targetDate }) => {
    const { minutes, seconds } = useCountdown(targetDate);
    if (seconds < 0 || minutes < 0) return null
    return moment(`${minutes}:${seconds}`, "H:m").format("HH:mm")
};

const Header = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(({ general }) => general)
    const { currentPeriod } = useSelector(({ game }) => game)
    const { pathname } = useLocation()
    const { offline, year } = useQuery()
    const periods = useMemo(() => getPeriods(offline, year), [offline, year])
    const isGame = useMemo(() => pathname.includes('game'), [pathname])
    const hasStarted = useMemo(() => currentPeriod >= 0, [currentPeriod])
    const hasFinished = useMemo(() => currentPeriod === 19, [currentPeriod])

    const logout = async () => {
        try {
            await signOut(auth)
            navigate("/")
        }
        catch (error) { Swal.fire({ title: error.message, icon: 'error' }) }
    }

    const [timestamp, setTimeStamp] = useState(null)
    useEffect(() => {
        if (!offline) {
            const currentPeriodRef = ref(database, '/timestamp')
            const listener = onValue(currentPeriodRef, (snapshot) => {
                setTimeStamp(moment(snapshot.val()).add(241, 'seconds').toDate())
            })
            return () => { listener() }
        }
    }, [offline])

    return <div className="header-container row">
        <Link to="/"><img alt="Logo" src={require('../../assets/images/logo.png')} /></Link>
        <div className="row row-buttons">
            {isGame && currentPeriod !== 20 && <>
                {<p className="period">Период: {periods[currentPeriod]} ({currentPeriod + 1} от 20)</p>}
                {!offline && hasStarted && !hasFinished && timestamp && (
                    <p className="timer"><CountdownTimer targetDate={timestamp} /></p>
                )}
                {offline && !hasFinished && <Shared.Button className="next-period" text="Следващ период" onClick={() => dispatch(setNextPeriod())} background="#00ce14" />}
                <div className="divider" />
            </>}
            {user ? <>
                {pathname === "/" && <>
                    <Shared.Button text="Към играта" onClick={() => {
                        if (!user) dispatch(setModal({ isOpen: true, type: 'Register' }))
                        else navigate('/game')
                    }} />
                    <Popup
                        className='popup-header-options-container'
                        trigger={<div className="shared-button-container">Играй офлайн</div>}
                        position="bottom "
                        offsetY={5}
                    >
                        {() => <div className="col">
                            <div className="row row-action" onClick={() => navigate('/game?offline=true&year=2022')}><p>2022</p></div>
                            <div className="row row-action" onClick={() => navigate('/game?offline=true&year=2021')}><p>2021</p></div>
                        </div>}
                    </Popup>
                    <div className="divider" />
                </>}
                <Popup
                    className='popup-header-options-container'
                    trigger={<div className="user-initials-container">{user?.displayName?.split(" ").map(([i]) => i).join("")}</div>}
                    position="bottom right"
                    offsetY={5}
                >
                    {() => <div className="row row-action" onClick={logout}>
                        <div className="icon icon-logout" />
                        <p>Изход</p>
                    </div>}
                </Popup>
            </> : <>
                <Shared.Button text="Вход" onClick={() => dispatch(setModal({ isOpen: true, type: 'Login' }))} />
                <Shared.Button text="Регистрация" onClick={() => dispatch(setModal({ isOpen: true, type: 'Register' }))} />
            </>}
        </div>
    </div>
}

export default Header